<template>
  <v-footer app inset absolute color="background">
    <v-col cols="12">
      Made with ❤️ using <strong>VueJS</strong>, <strong>Vuetify</strong>,
      <strong>Firebase</strong> and <strong>PokeAPI</strong>.
    </v-col>
  </v-footer>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>