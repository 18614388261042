<template>
  <v-app>
    <v-main>
      <v-container>
        <slot></slot>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>

export default {
  components: {
  },
  data() {
    return {};
  },
};
</script>